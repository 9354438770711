import React from 'react'
import { Link } from 'react-router-dom'
import About from "../../images/about/matriz.png"

const About2 = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="wpo-about-section-s2 section-padding">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="about-img">
                            <img src={About} alt="" />
                                <div className="images-text">
                                    <h3>38 Años de Experiencia.</h3>
                                </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="about-content">
                            <div className="wpo-section-title">
                                <span>Grupo Disosa</span>
                                <h2>Acerca de nosotros.</h2>
                            </div>
                            <div className="wpo-about-text">
                                <p>Desde su fundación en 1986 Disosa se ha dedicado a la
fabricación y distribución de productos químicos para una gran
cantidad de sectores industriales tales como:
                                </p>
                                <ul>
                                    <li>Industria tequilera</li>
                                    <li>Industria refresquera</li>
                                    <li>Industria minera</li>
                                    <li>Ingenios azucareros</li>
                                    <li>Industria farmacéutica</li>
                                    <li>Industria automotriz</li>
                                    <li>Industria agrícola</li>
                                    <li>Industria alimenticia</li>
                                    <li>Industria de la limpieza</li>
                                </ul>
                                {/* <Link onClick={ClickHandler} className="theme-btn color-9" to="/about">Discover More</Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About2;