
const industrial = {
    title: 'Productos Industriales',
    subtitle: 'Entendemos que cada industria tiene necesidades únicas. Por eso, ofrecemos soluciones personalizadas que se adaptan perfectamente a los requerimientos específicos de tu negocio. Nuestro equipo de expertos trabaja contigo para desarrollar productos que maximicen tu eficiencia y competitividad.',
    name: 'Industrial',
    productos: [
        "Ácido acético glacial",
        "Ácido clorhídrico Q.P., industrial",
        "Ácido fosfórico (Técnico al 75% y 85%)",
        "Ácido nítrico Q.P., industrial",
        "Ácido sulfúrico Q.P., industrial",
        "Bentonita",
        "Bisulfito de amonio",
        "Bisulfito de sodio",
        "Bórax anhidro",
        "Bórax decahidratado",
        "Bórax pentahidratado",
        "Carbonato de sodio",
        "Cloro al 90% polvo y granular",
        "Cloruro de calcio anhidro",
        "Cloruro de magnesio",
        "Cloruro de potasio",
        "Cloruro férrico",
        "Dióxido de cloro",
        "Formol",
        "Hidrosulfito de sodio",
        "Hidróxido de calcio",
        "Hipoclorito de calcio (HTH)",
        "Hipoclorito de sodio al 13%",
        "Lauril eter sulfato de sodio 28% y 70%",
        "Metabisulfito de sodio",
        "Metasilicato de sodio",
        "Nitrato de calcio líquido",
        "Nitrato de sodio",
        "Nonil fenol de 10 moles",
        "Óxido de calcio",
        "Pastilla de cloro 1” y 3”",
        "Permanganato de potasio",
        "Peróxido de hidrógeno al 50%",
        "Propilenglicol",
        "Potasa cáustica al 50%",
        "Sal industrial (Cloruro de sódio)",
        "Sosa cáustica líquida",
        "Sosa en escamas",
        "Sulfato de aluminio",
        "Sulfato de amonio",
        "Sulfato de cobre",
        "Sulfato de sodio",
        "Sulfato de zinc",
        "Sulfato ferroso",
        "Sulfuro de sodio",
        "Tierra diatomácea",
        "Tripolifosfato",
        "Urea",
        "Yodo"
      ]
}

export default industrial

