import React from 'react'
import { Link } from 'react-router-dom'
import SectionTitle from '../SectionTitle/SectionTitle'
import abImg1 from '../../images/about/1.jpg'
import abImg2 from '../../images/about/2.jpg'
import abImg3 from '../../images/about/3.jpg'
import Signeture from '../../images/signeture.png'
import Shape1 from '../../images/about/about-shape-1.png'
import Shape2 from '../../images/about/about-shape-2.png'
import productos from '../../api/productos'


const FarmaceuticoC = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="wpo-about-section section-padding">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 order-lg-1 order-2">
                        <div className="wpo-about-left">
                          
                            <div className="shape-1">
                                <img src={Shape1} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 order-lg-2 order-1">
                        <div className="wpo-about-right">
                            <SectionTitle subtitle='Farmacéutico' title='Productos Sector Farmacéutico' paragarap='Impulsando la innovación y la calidad en la industria farmacéutica con productos químicos de alta pureza y eficacia comprobada, garantizando la salud y el bienestar de las personas.'/>
                           
                        </div>
                    </div>
                </div>
            </div>
            <div className="shape-2">
                <img src={Shape1} alt="" />
            </div>
            <hr></hr>
            
        </section>

        
    )
}

export default FarmaceuticoC;



