import React from 'react';
import {Link} from 'react-router-dom'
import BlogSidebar from '../BlogSidebar/BlogSidebar.js'
import VideoModal from '../ModalVideo/VideoModal'
import blogs from '../../api/sucursales'




const Sucursal = (props) => {
    return(
        <section className="wpo-blog-pg-section section-padding">
            <div className="container">
                <div className="row">
                    <div className={`col col-lg-10 col-12 ${props.blRight}`}>
                        <div className="wpo-blog-content">
                            {blogs.slice(0, 5).map((blog, bitem) => (
                                <div className={`post  ${blog.blClass}`}key={bitem}>
                                    <div className="entry-media video-holder">
                                        <img src={blog.blogSingleImg} alt=""/>
                                        {/* <VideoModal/> */}
                                    </div>
                                    <div className="entry-meta">
                                        <ul>
                                            <li><i className="fi flaticon-user"></i> By {blog.authorTitle} </li>
                                            {/* <li><i className="fi flaticon-comment-white-oval-bubble"></i> Comments {blog.comment} </li> */}
                                            <li><i className="fi flaticon-calendar"></i> {blog.create_at}</li>
                                        </ul>
                                    </div>
                                    <div className="entry-details">
                                        <h3>{blog.title}</h3>
                                        <p>Dirección: {blog.address}</p>
                                        <p>CP: {blog.cp}</p>
                                        <p>Télefono: {blog.telefono}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {/* <BlogSidebar blLeft={props.blLeft}/> */}
                </div>
            </div>
        </section>

     )
        
}

export default Sucursal;
