// images
import blogImg1 from "../images/blog/1.jpg";
import recent1 from "../images/recent-posts/img-1.jpg";
import recent2 from "../images/recent-posts/img-2.jpg";
import recent3 from "../images/recent-posts/img-3.jpg";


import blogSingleImg1 from "../images/blog/img-4.jpg";
import blogSingleImg2 from "../images/blog/img-5.jpg";
import blogSingleImg3 from "../images/blog/img-6.jpg";



const blogs = [
    {
        id: '1',
        title: 'Surcursal SILAO',
        slug: '1',
        description: 'En Grupo Disosa, estamos comprometidos con la mejora continua de nuestros servicios y la expansión de nuestra presencia global. Nos complace anunciar la apertura de una nueva sucursal, diseñada para optimizar nuestra logística y ampliar significativamente nuestra área de cobertura a nivel internacional.',
        create_at: '27 - Mayo - 2024',
        screens: blogImg1,
        recent: recent1,
        blogSingleImg: blogSingleImg1,
        comment: '35',
        day: '28',
        month: 'AUGUST',
        blClass: 'format-standard-image',
        animation: '1200',
    },
    {
        id: '2',
        title: 'Agradecimiento',
        slug: '2',
        description: 'Agradecemos a nuestros clientes y socios por su continuo apoyo y confianza. Seguiremos trabajando arduamente para superar sus expectativas y contribuir al éxito de sus negocios.',
        create_at: '05 - Junio - 2024',
        screens: blogImg1,
        recent: recent2,
        blogSingleImg: blogSingleImg2,
        comment: '35',
        day: '28',
        month: 'AUGUST',
        blClass: 'format-standard-image',
        animation: '1200',
    },
    {
        id: '3',
        title: 'Beneficios para Nuestros Clientes ',
        slug: '3',
        description: 'Atención Personalizada: La proximidad a nuestros clientes nos permite ofrecer un servicio más personalizado y adaptado a sus necesidades específicas.',
        create_at: '12 - Enero - 2023',
        screens: blogImg1,
        recent: recent3,
        blogSingleImg: blogSingleImg3,
        comment: '35',
        day: '28',
        month: 'AUGUST',
        blClass: 'format-standard-image',
        animation: '1200',
    },
   
   

];
export default blogs;