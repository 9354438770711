import timg1 from '../images/team/tds.jpg'
import timg2 from '../images/team/sillas.jpg'
import timg3 from '../images/team/2tds.jpg'
import timg4 from '../images/team/silao.jpg'


import simg1 from '../images/team/img-1.jpg'
import simg2 from '../images/team/img-2.jpg'
import simg3 from '../images/team/img-3.jpg'
import simg4 from '../images/team/img-4.jpg'
import simg5 from '../images/team/img-4.jpg'

import himg1 from '../images/slider/hero-team/1.jpg'
import himg2 from '../images/slider/hero-team/2.jpg'
import himg3 from '../images/slider/hero-team/3.jpg'
import himg4 from '../images/slider/hero-team/4.jpg'

const Teams = [
   {
      Id: '1',
      img: timg1,
      Timg: simg1,
      himg:himg1,
      title: 'TDS',
      slug: '1',
      subtitle: 'Empresa Hermana',
      service:'Industrial, Mechanical',
      exp:'12 Years',
      add:'Millington, Ave, TN 38053',
      phone:'+00 568 746 987',
      email:'youremail@gmail.com',
      fax:' 568 746 987'
   },
   {
      Id: '2',
      img: timg2,
      Timg: simg2,
      himg:himg2,
      title: 'Nueva Sucursal SILAO',
      slug: '2',
      subtitle: 'Nuevo Proyecto',
      service:'Industrial, Mechanical',
      exp:'10 Years',
      add:'Millington, Ave, TN 38053',
      phone:'+00 568 746 987',
      email:'youremail@gmail.com',
      fax:' 568 746 987'
   },
   {
      Id: '3',
      img: timg3,
      Timg: simg3,
      himg:himg3,
      title: 'Flotilla de 36 unidades',
      slug: '3',
      subtitle: 'Transportes TDS',
      service:'Industrial, Mechanical',
      exp:'14 Years',
      add:'Millington, Ave, TN 38053',
      phone:'+00 568 746 987',
      email:'youremail@gmail.com',
      fax:' 568 746 987'
   },
   {
      Id: '4',
      img: timg4,
      Timg: simg4,
      himg:himg4,
      title: 'Nueva Sucursal, Izcalli en CDMX',
      slug: '4',
      subtitle: 'Sucursal',
      service:'Industrial, Mechanical',
      exp:'09 Years',
      add:'Millington, Ave, TN 38053',
      phone:'+00 568 746 987',
      email:'youremail@gmail.com',
      fax:' 568 746 987'
   },





]

export default Teams;