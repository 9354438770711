import React from 'react';
import ContactForm from '../ContactFrom/ContactForm'


const Contactpage = () => {

    return(
        <section className="wpo-contact-pg-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-10 offset-lg-1">
                        <div className="office-info">
                            <div className="row">
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-location"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Dirección </h2>
                                            <p>Prolongación López Mateos Sur #1800, Cond. Industrial Santa Cruz de la Flores #63,Tlajomulco de Zuñiga, Jalisco, México.</p>
                                        </div>
                                    </div>
                                </div> 
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-email"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Email </h2>
                                            <p>atencionclientes@disosa.com</p>
                                            
                                        </div>
                                    </div>
                                </div> 
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-phone-call"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Llama ahora</h2>
                                            <p>+52 333 145 0555</p>
                                            <p>+52 333 145 1139</p>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                        <div className="wpo-contact-title">
                            <h2>¿Tienes alguna pregunta?</h2>
                            <p>Nuestros especialistas están siempre disponibles para responder tus dudas y brindarte el apoyo que necesites.</p>
                        </div>
                        <div className="wpo-contact-form-area">
                            <ContactForm/>
                        </div>
                    </div>                
                </div>
            </div> 
            <section className="wpo-contact-map-section">
                <div className="wpo-contact-map">
                    <iframe title='comtact-map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d239212.40312687124!2d-103.6635100328758!3d20.4777659130339!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842f55dacdb055e1%3A0x364a00930aec90bb!2sGrupo%20Disosa%20Matriz!5e0!3m2!1ses-419!2smx!4v1717774425944!5m2!1ses-419!2smx"></iframe>
                </div>
            </section>
        </section>
     )
        
}

export default Contactpage;
