// images
import blogImg1 from "../images/blog/1.jpg";

import recent1 from "../images/recent-posts/img-1.jpg";
import recent2 from "../images/recent-posts/img-2.jpg";
import recent3 from "../images/recent-posts/img-3.jpg";
import recent4 from "../images/recent-posts/img-2.jpg";


import blogSingleImg1 from "../images/blog/img-4.jpg";
import blogSingleImg2 from "../images/blog/img-5.jpg";
import blogSingleImg3 from "../images/blog/img-6.jpg";


import matriz from "../images/blog/matriz.jpg";
import zona from "../images/blog/zona.jpg";
import mx from "../images/blog/mx.jpg";
import silao from "../images/blog/silao.jpg";
import hermosillo from "../images/blog/Hermosillo.jpg";






const sucursales = [
    {
        id: '1',
        title: 'MATRIZ TLAJOMULCO DE ZUÑIGA',
        slug: '1',
        description: 'En Grupo Disosa, estamos comprometidos con la mejora continua de nuestros servicios y la expansión de nuestra presencia global. Nos complace anunciar la apertura de una nueva sucursal, diseñada para optimizar nuestra logística y ampliar significativamente nuestra área de cobertura a nivel internacional.',
        create_at: '07 - Junio - 2024',
        address: 'Prolongación López Mateos Sur #1800, Cond. Industrial Santa Cruz de la Flores #63,Tlajomulco de Zuñiga, Jalisco, México.',
        cp: '45640',
        telefono: '+52 333145-0555',
        screens: blogImg1,
        recent: recent1,
        blogSingleImg: matriz,
        comment: '35',
        day: '28',
        month: 'AUGUST',
        blClass: 'format-standard-image',
        animation: '1200',
    },
   
    {
        id: '2',
        title: 'SUCURSAL GUADALAJARA',
        slug: '2',
        description: 'En Grupo Disosa, estamos comprometidos con la mejora continua de nuestros servicios y la expansión de nuestra presencia global. Nos complace anunciar la apertura de una nueva sucursal, diseñada para optimizar nuestra logística y ampliar significativamente nuestra área de cobertura a nivel internacional.',
        create_at: '07 - Junio - 2024',
        address: 'Calle #20 Número 2680, Guadalajara, Jalisco, México.',
        telefono: '+52 333145-1139',
        cp:'44940',
        screens: blogImg1,
        recent: recent1,
        blogSingleImg: zona,
   
    },
    {
        id: '3',
        title: 'SUCURSAL CDMX',
        slug: '3',
        description: 'En Grupo Disosa, estamos comprometidos con la mejora continua de nuestros servicios y la expansión de nuestra presencia global. Nos complace anunciar la apertura de una nueva sucursal, diseñada para optimizar nuestra logística y ampliar significativamente nuestra área de cobertura a nivel internacional.',
        create_at: '07 - Junio - 2024',
        address: ' Andre Marie Ampero, 07. Cuatitlan Izcalli.',
        telefono: '+55 5872-5236',
        cp:'36283',
        screens: blogImg1,
        recent: recent1,
        blogSingleImg: mx,
   
    },
    {
        id: '4',
        title: 'SUCURSAL GUANAJUATO',
        slug: '4',
        description: 'En Grupo Disosa, estamos comprometidos con la mejora continua de nuestros servicios y la expansión de nuestra presencia global. Nos complace anunciar la apertura de una nueva sucursal, diseñada para optimizar nuestra logística y ampliar significativamente nuestra área de cobertura a nivel internacional.',
        create_at: '07 - Junio - 2024',
        address: 'Novotech, San Isidro El Arenal, 36294 Silao de la Victoria, Gto.',
        telefono: '+52 33 3145-0555',
        cp:'36283',
        screens: blogImg1,
        recent: recent1,
        blogSingleImg: silao,
   
    },
    {
        id: '5',
        title: 'SUCURSAL HERMOSILLO',
        slug: '4',
        description: 'En Grupo Disosa, estamos comprometidos con la mejora continua de nuestros servicios y la expansión de nuestra presencia global. Nos complace anunciar la apertura de una nueva sucursal, diseñada para optimizar nuestra logística y ampliar significativamente nuestra área de cobertura a nivel internacional.',
        create_at: '07 - Junio - 2024',
        address: 'Blvd. Garcia Morales 785A, kilómetro 7.5, almacén 6, andenes 28 al 33, Colonia El Llano, Hermosillo, Sonora.',
        telefono: '+66 2261-1189',
        cp:'83220',
        screens: blogImg1,
        recent: recent1,
        blogSingleImg: hermosillo,
   
    },
   

];
export default sucursales;