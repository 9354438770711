import React from 'react'

const Listado = ({productos}) => {
  return (
    <div className='text-center'>
        <br></br>
        <h2>Listado de productos</h2>
        <br></br>
        <ul>
            {productos.map(e=>{
                return <li>{e}</li>
            })}
        </ul>
        <br></br>
    </div>
  )
}

export default Listado