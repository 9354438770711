import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar'
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Logo2 from '../../images/logo.png'
import About from '../about/about';
import Listado from './Listado';
import productos from '../../api/productos';
import AlimenticioC from '../about/alimenticio';




const Alimentacion = () => {

    const data =  [
        "Ácido acético glacial GA",
        "Ácido ascórbico",
        "Ácido cítrico",
        "Ácido fosfórico (G.A al 75% y 85%)",
        "Ácido graso",
        "Aspartame",
        "Benzoato de sodio granular",
        "Citrato de sodio",
        "Cloruro de calcio anhidro GA",
        "Goma xanthan",
        "Hexametafosfato de sodio GA",
        "Metabisulfito de sodio GA",
        "Natamicina",
        "Peróxido de hidrógeno G.A. 35%",
        "Cloruro de sodio G.A.",
        "Sorbato de potasio granular",
        "Sorbitol",
        "Dióxido de cloro (desinfección)"
      ]
  return (
    <Fragment>
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo2}  />
            <AlimenticioC></AlimenticioC>
            <Listado productos={data}></Listado>
            <Footer />
            <Scrollbar />
        </Fragment>
  )
}

export default Alimentacion