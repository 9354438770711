import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar'
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Logo2 from '../../images/logo.png'
import About from '../about/about';
import Listado from './Listado';
import productos from '../../api/productos';

const Industrial = () => {
  return (
    <Fragment>
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo2}  />
            <About></About>
            <Listado productos={productos.productos}></Listado>
            <Footer />
            <Scrollbar />
        </Fragment>
  )
}

export default Industrial