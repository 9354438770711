import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import Logo2 from '../../images/logo.png'
import Sucursal from '../../components/Sucursales/Sucursal';
const ProjectPage = () => {
    return (
        <Fragment>
            <Navbar Logo={Logo2} />
            <PageTitle pageTitle={'Sucursales'} pagesub={'Sucursales'} />
            <Sucursal/>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default ProjectPage;
