import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar'
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Logo2 from '../../images/logo.png'
import About from '../about/about';
import Listado from './Listado';
import productos from '../../api/productos';
import AlimenticioC from '../about/alimenticio';
import FarmaceuticoC from '../about/farmaceutico';




const Farmaceutico = () => {

    const data =  [
        "Ácido acético glacial",
        "Ácido ascórbico",
        "Ácido cítrico",
        "Ácido fosfórico",
        "Benzoato de sodio",
        "Glicerina",
        "Sorbitol",
        "Sorbato de potasio"
      ]
  return (
    <Fragment>
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo2}  />
            <FarmaceuticoC></FarmaceuticoC>
            <Listado productos={data}></Listado>
            <Footer />
            <Scrollbar />
        </Fragment>
  )
}

export default Farmaceutico