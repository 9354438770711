import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar'
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Logo2 from '../../images/logo.png'
import About from '../about/about';
import Listado from './Listado';
import productos from '../../api/productos';
import AlimenticioC from '../about/alimenticio';
import MineroC from '../about/minero';




const Minero = () => {

    const data =  [
        "Ácido acético glacial",
        "Ácido clorhídrico",
        "Ácido nítrico",
        "Ácido sulfónico",
        "Ácido sulfúrico",
        "Bisulfito de amonio",
        "Bisulfito de sodio",
        "Bórax anhidro",
        "Bórax decahidratado",
        "Bórax pentahidratado",
        "Hidrosulfito de sodio",
        "Hidróxido de calcio",
        "Metabisulfito de sodio",
        "Metasilicato de sodio",
        "Nitrato de calcio líquido",
        "Nitrato de sodio",
        "Sosa cáustica líquida",
        "Sosa en escamas",
        "Sulfato de aluminio",
        "Sulfato de amonio",
        "Sulfato de cobre",
        "Sulfato de sodio",
        "Sulfato de zinc",
        "Sulfuro de sodio"
      ]
  return (
    <Fragment>
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo2}  />
            <MineroC></MineroC>
            <Listado productos={data}></Listado>
            <Footer />
            <Scrollbar />
        </Fragment>
  )
}

export default Minero