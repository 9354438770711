import simg1 from '../images/service/1.jpg'
import simg2 from '../images/service/1.jpg'
import simg3 from '../images/service/1.jpg'


import sSimg1 from '../images/service-single/img-1.jpg'
import sSimg2 from '../images/service-single/img-2.jpg'
import sSimg3 from '../images/service-single/img-3.jpg'





const Services = [
   {
      Id: '01',
      sImg: simg1,
      sSImg: sSimg1,
      title: 'ISO 22000:2018',
      slug: 'iso_22000_2018',
      description: 'Esta norma se aplica a todas las organizaciones involucradas en cualquier aspecto de la cadena alimentaria.',
   },
   {
      Id: '02',
      sImg: simg2,
      sSImg: sSimg2,
      title: 'FDA',
      slug: 'FDA',
      description: 'La FDA es responsable de proteger y promover la salud pública mediante la regulación y supervisión de diversos productos.',
   },
   {
      Id: '03',
      sImg: simg3,
      sSImg: sSimg3,
      title: 'NSF',
      slug: 'NSF',
      description: 'La NSF desarrolla estándares y certificaciones para productos, sistemas y servicios que impactan la salud pública.',
   },
  
 
]

export default Services;