import pimg1 from '../images/project/educacion.jpg'
import pimg2 from '../images/project/2.jpg'
import pimg3 from '../images/project/3.jpg'
import pimg4 from '../images/project/4.jpg'
import pimg5 from '../images/project/5.jpg'
import pimg6 from '../images/project/6.jpg'
import pimg7 from '../images/project/7.jpg'
import pimg8 from '../images/project/8.jpg'
import pimg9 from '../images/project/9.jpg'




const Projects = [
    {
        id: '1',
        title: 'Apoyo a la Educación',
        subtitle: 'EDUCACIÓN / SOCIAL',
        slug: '1',
        pimg1: pimg1,
        date: 'Feb 2019',
        location: 'Austria',
        type: 'Civil Engineer',
        service: 'Industrial, Mechanical',
        duration: '12 Years',
        add: 'Millington, Ave, TN 38053',
        architect: 'Don Johnson',
        share: 'Industrial, Business',
        fax: ' 568 746 987'
    },
    {
        id: '2',
        title: 'Colaboración con Casas Hogar',
        subtitle: 'SOCIAL / HOGAR',
        slug: '2',
        pimg1: pimg2,
        date: 'Feb 2019',
        location: 'Austria',
        type: 'Civil Engineer',
        service: 'Industrial, Mechanical',
        duration: '12 Years',
        add: 'Millington, Ave, TN 38053',
        architect: 'Don Johnson',
        share: 'Industrial, Business',
        fax: ' 568 746 987'
    },
    {
        id: '3',
        title: 'Donaciones',
        subtitle: 'DONACIONES ',
        slug: '3',
        pimg1: pimg3,
        date: 'Feb 2019',
        location: 'Austria',
        type: 'Civil Engineer',
        service: 'Industrial, Mechanical',
        duration: '12 Years',
        add: 'Millington, Ave, TN 38053',
        architect: 'Don Johnson',
        share: 'Industrial, Business',
        fax: ' 568 746 987'
    },
   


]
export default Projects;