import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar'
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Logo2 from '../../images/logo.png'
import Listado from './Listado';

import EnergeticoC from '../about/energetico';




const Energetico = () => {

    const data =  [
        "Ácido clorhídrico",
        "Ácido fosfórico",
        "Ácido nítrico",
        "Ácido sulfúrico",
        "Cloro al 90% polvo y granular",
        "Cloruro férrico",
        "Dióxido de cloro",
        "Hipoclorito de calcio (HTH)",
        "Hipoclorito de sodio al 13%",
        "Pastilla de cloro 1” y 3”",
        "Potasa cáustica al 50%",
        "Sal industrial (Cloruro de sódio)",
        "Sosa cáustica líquida",
        "Sosa en escamas",
        "Sulfato de aluminio",
        "Urea"
      ]
  return (
    <Fragment>
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo2}  />
            <EnergeticoC></EnergeticoC>
            <Listado productos={data}></Listado>
            <Footer />
            <Scrollbar />
        </Fragment>
  )
}

export default Energetico